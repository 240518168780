var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"wr_grey_1 mt-15",attrs:{"flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"12"}},[(_vm.table_chart_columns.length)?_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.table_chart_columns),function(column,colIndex){return _c('v-col',{key:colIndex,class:[
						'column-wr',
						{
							'left-border-none':
								!_vm.$vuetify.rtl &&
								_vm.table_chart_columns.length > 1 &&
								colIndex !== 0,
							'right-border-none':
								_vm.$vuetify.rtl &&
								_vm.table_chart_columns.length > 1 &&
								colIndex !== 0,
						} ],style:({ background: column.color })},[_c('v-row',{staticClass:"font-weight-bold",attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"hide-details":"","solo":"","flat":"","placeholder":_vm.$t('app.placeholder.type_title_here'),"background-color":column.color},on:{"blur":function($event){return _vm.updateColumn(colIndex, column.title)}},model:{value:(column.title),callback:function ($$v) {_vm.$set(column, "title", $$v)},expression:"column.title"}},[(_vm.table_chart_columns.length > 3 && !_vm.isFinalStep)?_c('v-icon',{staticClass:"column_removal_icon-wr",attrs:{"slot":"append","color":"black"},on:{"click":function($event){return _vm.removeColumn(colIndex)}},slot:"append"},[_vm._v(_vm._s(_vm.$vuetify.icons.values.close)+" ")]):_vm._e()],1)],1),_vm._l((column.terms),function(term,index){return _c('v-row',{key:index,class:[
							'terms-row-wr left-border-none right-border-none',
							{
								'bottom-border-none': index < column.terms.length,
							} ],attrs:{"no-gutters":""}},[(colIndex == 0 && column.terms.length > 2 && !_vm.isFinalStep)?_c('v-icon',{staticClass:"mt-2 row-removal-icon-wr",style:({
								right: _vm.$vuetify.rtl ? '' : '30px',
								left: _vm.$vuetify.rtl ? '30px' : '',
							}),on:{"click":function($event){return _vm.removeRow(index)}}},[_vm._v("$vuetify.icons.values.close")]):_vm._e(),_c('v-text-field',{attrs:{"hide-details":"","solo":"","flat":"","background-color":column.color},on:{"blur":function($event){return _vm.updateTerm(colIndex, index, 'text', term.text)}},model:{value:(term.text),callback:function ($$v) {_vm.$set(term, "text", $$v)},expression:"term.text"}},[(_vm.isFinalStep)?_c('v-icon',{staticClass:"fav_icon-wr",attrs:{"slot":"append","color":term.fav ? 'error' : 'black'},on:{"click":function($event){return _vm.updateTerm(colIndex, index, 'fav', !term.fav)}},slot:"append"},[_vm._v(_vm._s(_vm.$vuetify.icons.values[term.fav ? "heart" : "heart_outline"])+" ")]):_vm._e()],1)],1)})],2)}),(!_vm.isFinalStep)?_c('v-card',{staticClass:"wr_grey_1",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-icon',{attrs:{"large":"","color":"error"},on:{"click":function($event){return _vm.addColumn()}}},[_vm._v(" $vuetify.icons.values.plus ")])],1)],1):_vm._e()],2):_vm._e()],1)],1),(_vm.table_chart_columns.length && !_vm.isFinalStep)?_c('v-card',{staticClass:"wr_grey_1",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-1"},[_c('v-icon',{attrs:{"large":"","color":"error"},on:{"click":function($event){return _vm.addRow()}}},[_vm._v(" $vuetify.icons.values.plus ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }