<template>
	<v-card flat class="wr_grey_1 mt-15">
		<v-row no-gutters>
			<v-col sm="12">
				<v-row no-gutters v-if="table_chart_columns.length">
					<v-col
						v-for="(column, colIndex) in table_chart_columns"
						:key="colIndex"
						:style="{ background: column.color }"
						:class="[
							'column-wr',
							{
								'left-border-none':
									!$vuetify.rtl &&
									table_chart_columns.length > 1 &&
									colIndex !== 0,
								'right-border-none':
									$vuetify.rtl &&
									table_chart_columns.length > 1 &&
									colIndex !== 0,
							},
						]"
					>
						<v-row no-gutters class="font-weight-bold">
							<v-text-field
								v-model="column.title"
								hide-details
								solo
								flat
								:placeholder="$t('app.placeholder.type_title_here')"
								:background-color="column.color"
								@blur="updateColumn(colIndex, column.title)"
							>
								<v-icon
									v-if="table_chart_columns.length > 3 && !isFinalStep"
									class="column_removal_icon-wr"
									slot="append"
									color="black"
									@click="removeColumn(colIndex)"
									>{{ $vuetify.icons.values.close }}
								</v-icon>
							</v-text-field>
						</v-row>

						<v-row
							v-for="(term, index) in column.terms"
							:key="index"
							no-gutters
							:class="[
								'terms-row-wr left-border-none right-border-none',
								{
									'bottom-border-none': index < column.terms.length,
								},
							]"
						>
							<v-icon
								class="mt-2 row-removal-icon-wr"
								:style="{
									right: $vuetify.rtl ? '' : '30px',
									left: $vuetify.rtl ? '30px' : '',
								}"
								v-if="colIndex == 0 && column.terms.length > 2 && !isFinalStep"
								@click="removeRow(index)"
								>$vuetify.icons.values.close</v-icon
							>
							<v-text-field
								v-model="term.text"
								hide-details
								solo
								flat
								:background-color="column.color"
								@blur="updateTerm(colIndex, index, 'text', term.text)"
							>
								<v-icon
									class="fav_icon-wr"
									v-if="isFinalStep"
									slot="append"
									:color="term.fav ? 'error' : 'black'"
									@click="updateTerm(colIndex, index, 'fav', !term.fav)"
									>{{
										$vuetify.icons.values[term.fav ? "heart" : "heart_outline"]
									}}
								</v-icon>
							</v-text-field>
						</v-row>
					</v-col>
					<v-card flat class="wr_grey_1" v-if="!isFinalStep">
						<v-card-text class="py-0">
							<v-icon large color="error" @click="addColumn()">
								$vuetify.icons.values.plus
							</v-icon>
						</v-card-text>
					</v-card>
				</v-row>
			</v-col>
		</v-row>
		<v-card
			flat
			class="wr_grey_1"
			v-if="table_chart_columns.length && !isFinalStep"
		>
			<v-card-text class="px-1">
				<v-icon large color="error" @click="addRow()">
					$vuetify.icons.values.plus
				</v-icon>
			</v-card-text>
		</v-card>
	</v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "ChooseTopicAltTableChartManagement",

	computed: {
		...mapState({
			table_chart_columns: (state) => state.mindmap.table_chart_columns,
			current_step: (state) => state.process.current_step,
		}),

		isFinalStep() {
			return this.current_step.name === "selectFavTerms";
		},
	},

	methods: {
		addColumn() {
			this.$store.commit("mindmap/ADD_COLUMN");
		},

		removeColumn(index) {
			this.$store.commit("mindmap/REMOVE_COLUMN", {
				index,
			});
		},

		addRow() {
			this.$store.commit("mindmap/ADD_ROW");
		},

		removeRow(index) {
			this.$store.commit("mindmap/REMOVE_ROW", {
				index,
			});
		},

		updateColumn(index, title) {
			this.$store.commit("mindmap/UPDATE_COLUMN", {
				index,
				title,
			});
		},

		updateTerm(colIndex, index, property, value) {
			this.$store.commit("mindmap/UPDATE_TERM", {
				colIndex,
				index,
				property,
				value,
			});
		},
	},
};
</script>

<style scoped>
.column-wr,
.terms-row-wr {
	border: 1px solid;
}

.right-border-none {
	border-right: none;
}
.top-border-none {
	border-top: none;
}
.left-border-none {
	border-left: none;
}
.bottom-border-none {
	border-bottom: none;
}
>>> .v-text-field .v-input__control {
	border-radius: 0px;
}
.row-removal-icon-wr {
	position: absolute;
	z-index: 1;
}
>>> input:-internal-autofill-selected {
	background: transparent !important;
}
.column_removal_icon-wr,
.row-removal-icon-wr,
.fav_icon-wr {
	font-size: 18px !important;
	color: black;
}
>>> .v-input input {
	text-align: center;
}
</style>
