<template>
	<v-card flat class="wr_grey_1 mt-2" align="center">
		<v-card flat width="500" class="wr_grey_1">
			<v-form ref="form">
				<v-text-field
					v-model="table_chart_title"
					outlined
					tile
					flat
					color="greenAccent2"
					background-color="white"
					:label="$t('app.placeholder.type_title_here')"
					:rules="[rules.required]"
				></v-text-field>
			</v-form>
		</v-card>
	</v-card>
</template>

<script>
import rules from "@/utils/validation";
import { mapFields } from "vuex-map-fields";
import { mapState } from "vuex";

export default {
	name: "ChooseTopicAltTableChartTitle",

	data() {
		return {
			rules,
		};
	},

	computed: {
		...mapState({
			current_step: (state) => state.process.current_step,
		}),

		...mapFields("mindmap", {
			table_chart_title: "table_chart_title",
		}),
	},

	created() {
		this.readyToListenEvents();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("tcm-validate-title", async (payload) => {
				// Redirect to next if title has been filled
				if (this.$refs.form.validate()) {
					this.$eventBus.$emit("tcm-redirect-next");
				}
			});
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("tcm-validate-title");
	},
};
</script>

<style scoped>
>>> .v-input input {
	text-align: center;
}
</style>
