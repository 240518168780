<template>
	<div>
		<TableChart @tcm-process-completed="openFinishDialog()"></TableChart>

		<!-- Ask before close mind map  -->
		<FinishConfirmationDialog
			v-model="dialog"
			:dialog="dialog"
			@close="dialog = false"
			@confirm="proceedToNext()"
		></FinishConfirmationDialog>
	</div>
</template>

<script>
import TableChart from "@/components/research/modules/defineQuestion/stages/chooseTopic/alternatives/tableChart/Index";
import { chooseTopicAlternativesMixin } from "@/mixins/chooseTopicAlternativesMixin";

export default {
	name: "ChooseTopicAltTableChartPage",

	mixins: [chooseTopicAlternativesMixin],

	components: {
		TableChart,
	},
};
</script>

<style></style>
