<template>
	<div>
		<TitlePreparation></TitlePreparation>

		<!-- Show only except first step -->
		<TableManagement v-if="current_step.value !== 1"></TableManagement>
	</div>
</template>

<script>
import TitlePreparation from "./steps/TitlePreparation.vue";
import TableManagement from "./steps/TableManagement.vue";
import { stepsManageMixin } from "@/mixins/stepsManageMixin";
import { tableChartSteps } from "@/utils/steps";

export default {
	name: "ChooseTopicAltTableChart",

	mixins: [stepsManageMixin],

	components: {
		TitlePreparation,
		TableManagement,
	},

	created() {
		this.$eventBus.$on("tcm-redirect-back", async (payload) => {
			this.redirectBack();
		});
		this.$eventBus.$on("tcm-redirect-next", async (payload) => {
			this.redirectNext();
		});
		this.$eventBus.$on("tcm-finish-work", async (payload) => {
			this.$emit("tcm-process-completed");
		});
	},

	mounted() {
		// This function is in mixin
		this.getAndSetSteps();
	},

	computed: {
		featureSteps() {
			return tableChartSteps;
		},
	},
};
</script>
